import './ComingSoon.css'


function ComingSoon() {
    return (

        <div>
            <div className="maintenance-header">
            </div>
            <div className="maintenance-body">
                <div className="maintenance-text">
                <h3>Under Construction</h3>
                <h4>STAY TUNED</h4>
                <h3 className='coming-soon-text'>COMING SOON</h3>
                </div>
            </div>

        </div>
    );
}
export default ComingSoon;


