import './App.css';
import Maintenance from './Construction/ComingSoon';

function App() {
  return (
    <div className="App">
      <Maintenance />
    </div>
  );
}

export default App;
